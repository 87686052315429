

  .animated{
    .operatorId{
      cursor: pointer;
    }
    button{
      padding-top:1px;
      padding-bottom:1px;
    }
    .spinner-border{
      width: 1rem;
      height: 1rem;
    }
    .newPassword{
      margin-top:5px;
      #copyOperatorCredentials{
          background: red
      }
    }
    .badge{
      width: 1em;
      height: 1em;
      border-radius: 10px;
    }
    .badge:empty{
      display: block;
    }
  }
  
  #assignOpertorModal{
    #assignHandler{
      font-size:18px !important;
    }
    #assignSuccessHandler{
      color:#4dbd74;
      display: none;
      font-size:18px !important;
    }
    button{
      padding-top:1px;
      padding-bottom:1px;
    }
  }

  .editOperator{
    float: right;
    margin: 0 auto;
    line-height: 2;
    font-size: 20px;
    margin-right: 10px;
  }
  
  .table th{
    // border: none !important;
  }

  .filterData{
    margin-bottom: 10px;
  }

  .perPage Form{
    float: right;
    .form-group{
      span{
        margin-right: 3px;
        margin-left: 3px;
      }
    }
  }

#editOperatorSuccessHandler{
  color:#4dbd74;
      display: none;
      font-size:18px !important;
}

#editOperatorHandler{
      font-size:18px !important;
}

.editOperatorOpen{
  cursor: pointer;
}
  
.operatorDetail{
  max-width: none !important;
  width:60% !important;
  margin: 0.5rem auto !important;
  table  .tbody-td{
    padding:3.5px !important
  }table .thead-th{
    padding:4.2px !important
  }
  #export{
    float: right;
  }
}

.table th, .table td{
  text-align: center
}

#operatorDetailsHandler {
  font-size: 18px;
  display: block !important;
}
