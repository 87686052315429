
#assignSubDealerModal{
    #assignHandler{
      font-size:18px !important;
    }
    #assignSuccessHandler{
      color:#4dbd74;
      display: none;
      font-size:18px !important;
    }
    button{
      padding-top:1px;
      padding-bottom:1px;
    }
  }

  #createSubDealerHandler{
    font-size:18px !important;
    display: none;
    color:#f86c6b
  }
  #createSuccessHandler{
    color:#4dbd74 !important;
    display: none;
    font-size:18px !important;
  }
    
  