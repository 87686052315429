.records{
  .nextPreviousButtons{
    float: right !important;
    border:1 px solid red !important;
    cursor: pointer;
    i:hover{
      color:#28abd9;
    }
  }
}

@media print{
  .printBtn, .breadcrumb, .nextPreviousButtons{
    display: none !important;
  }
}