// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

.text-right{
    // text-align: right !important;
    // bottom: 0;
    // position: absolute;
    // display: none;
}

.pagignation{
    button{
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
     
    }
}

.noOperatorRecords{
    position: absolute;
    width: 95%;
    margin-top: 8px;
    text-align: center
}

ul {
    .mt-auto {
        position: absolute;
        width: 100%;
        bottom: 0.5rem;
    }
}

#newOperatorFail {
    font-size: 18px;
    display: none;
    color: red;
}


