// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

@import './views/Admin/OperatorList/operator.scss';

@import './views/Admin/DealerList/dealer.scss';

@import './views/Admin/SubDealerList/subDealer.scss';

@import './views/Admin/Records/records.scss';

@media (min-width: 991.98px){
  .secondaryHeader {
      display: none;
  }
}
@media (max-width: 991.98px){
  .secondaryHeader {
      display: block;
  }
}
.secondaryHeader{
  position: relative;
  top: 3.8em;
  height: 2em;
  background-color: white;
  text-align: center;
  padding-top: 3px;
}

@media (max-width: 991.98px){
  .sidebar{
    height: calc(100vh - 73px) !important;
  }
}
@media (min-width: 992px){
  .sidebar{
    height: 96vh !important;
  }
}

.initialLoader{
  div {
      svg{
          position: absolute; /* this is needed */
          margin: auto; /* this is needed */
          left: 0; /* this is needed */
          right: 0; /* this is needed */
          top: 0; /* this is needed */
          bottom: 0; /* this is needed */
      }
  }
}

