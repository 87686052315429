
#assignDealerModal{
  #assignHandler{
    font-size:18px !important;
  }
  #assignSuccessHandler{
    color:#4dbd74 !important;
    display: none;
    font-size:18px !important;
  }
}

.animated{
  .addDealer{
    float: right;
    cursor: pointer;
  }

  #onClickName{
    cursor: pointer;
  }
  #onClickName:hover{
    color: #20a8d8  !important;
  }

}

#createDealerHandler{
  font-size:18px !important;
}
#createSuccessHandler{
  color:#4dbd74 !important;
  display: none;
  font-size:18px !important;
}

@page print{
  button{
    border: 1px solid red !important;
  }
  #data{
    border:1px solid red !important;
  }
}
  