// Here you can add other styles
.loaderWrapper {
    svg {
        position: absolute;
        top: 25%;
    }
}

.reportPercenteageColor{
    color:red;
}